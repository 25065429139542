export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
      },
    },
    {
      title: true,
      name: 'Posts',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Categories',
      url: '/categories',
      icon: 'icon-layers',
    },
    {
      name: 'Posts',
      url: '/post',
      icon: 'icon-pencil',
      children: [
        {
          name: 'Submitted Posts',
          url: '/submitted-post',
          icon: 'icon-energy',
        },
        {
          name: 'Posts',
          url: '/post',
          icon: 'icon-magic-wand',
        },

      ],
    },
    {
      name: 'Advertisements',
      url: '/advertisements',
      icon: 'icon-briefcase',
      children: [
        {
          name: 'Default Advertisements',
          url: '/defaultadvertisements',
          icon: 'icon-magic-wand',
        },
        {
          name: 'Submitted Advertisements',
          url: '/submitted-advertisements',
          icon: 'icon-energy',
        },
        {
          name: 'Accepted Advertisements',
          url: '/advertisements',
          icon: 'icon-magic-wand',
        },

      ],
    },
    // {
    //   title: true,
    //   name: 'Users',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Users',
    //   url: '/users',
    //   icon: 'icon-people',
    // },
    // {
    //   title:true,
    //   name: 'Payments and earnings',
    //   icon: 'icon-wallet',
    // },
    // {
    //   name: 'Payments',
    //   url: '/payments',
    //   icon: 'icon-pie-chart',
    // },
    // {
    //   name: 'Current Month Incomes',
    //   url: '/incomes',
    //   icon: 'icon-login',
    // },
    // // {
    // //   name: 'Current Month Views',
    // //   url: '/views',
    // //   icon: 'icon-eye',
    // // },
    // {
    //   name: 'Earnings',
    //   url: '/earnings',
    //   icon: 'icon-wallet',
    // },
    // {
    //   name: 'Payouts',
    //   url: '/payouts',
    //   icon: 'icon-logout',
    // },
    {
      title:true,
      name: 'CMS',
      icon: 'icon-calculator',
    },
    {
      name: 'Footer Links',
      url: '/footer-links',
      icon: 'icon-list',
    },
    {
      name: 'Live Stream',
      url: '/livestream',
      icon: 'icon-camera',
    },
    {
      name: 'Home Page Categories',
      url: '/homepage-categories',
      icon: 'icon-list',
    },
    {
      name: 'Featured block',
      url: '/featured-block',
      icon: 'icon-calculator',
    },
    {
      name: 'Default block',
      url: '/default-block',
      icon: 'icon-calculator',
    },

    // {
    //   name: 'Users Earnings',
    //   url: '/user-earnings',
    //   icon: 'icon-wallet',
    // },
    /*{
      title: true,
      name: 'Components',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Base',
      url: '/base',
      icon: 'icon-puzzle',
      children: [
        {
          name: 'Breadcrumbs',
          url: '/base/breadcrumbs',
          icon: 'icon-puzzle',
        },
        {
          name: 'Cards',
          url: '/base/cards',
          icon: 'icon-puzzle',
        },
        {
          name: 'Carousels',
          url: '/base/carousels',
          icon: 'icon-puzzle',
        },
        {
          name: 'Collapses',
          url: '/base/collapses',
          icon: 'icon-puzzle',
        },
        {
          name: 'Dropdowns',
          url: '/base/dropdowns',
          icon: 'icon-puzzle',
        },
        {
          name: 'Forms',
          url: '/base/forms',
          icon: 'icon-puzzle',
        },
        {
          name: 'Jumbotrons',
          url: '/base/jumbotrons',
          icon: 'icon-puzzle',
        },
        {
          name: 'List groups',
          url: '/base/list-groups',
          icon: 'icon-puzzle',
        },
        {
          name: 'Navs',
          url: '/base/navs',
          icon: 'icon-puzzle',
        },
        {
          name: 'Paginations',
          url: '/base/paginations',
          icon: 'icon-puzzle',
        },
        {
          name: 'Popovers',
          url: '/base/popovers',
          icon: 'icon-puzzle',
        },
        {
          name: 'Progress Bar',
          url: '/base/progress-bar',
          icon: 'icon-puzzle',
        },
        {
          name: 'Switches',
          url: '/base/switches',
          icon: 'icon-puzzle',
        },
        {
          name: 'Tables',
          url: '/base/tables',
          icon: 'icon-puzzle',
        },
        {
          name: 'Tabs',
          url: '/base/tabs',
          icon: 'icon-puzzle',
        },
        {
          name: 'Tooltips',
          url: '/base/tooltips',
          icon: 'icon-puzzle',
        },
      ],
    },
    {
      name: 'Buttons',
      url: '/buttons',
      icon: 'icon-cursor',
      children: [
        {
          name: 'Buttons',
          url: '/buttons/buttons',
          icon: 'icon-cursor',
        },
        {
          name: 'Button dropdowns',
          url: '/buttons/button-dropdowns',
          icon: 'icon-cursor',
        },
        {
          name: 'Button groups',
          url: '/buttons/button-groups',
          icon: 'icon-cursor',
        },
        {
          name: 'Brand Buttons',
          url: '/buttons/brand-buttons',
          icon: 'icon-cursor',
        },
      ],
    },
    {
      name: 'Charts',
      url: '/charts',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Icons',
      url: '/icons',
      icon: 'icon-star',
      children: [
        {
          name: 'CoreUI Icons',
          url: '/icons/coreui-icons',
          icon: 'icon-star',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        },
        {
          name: 'Flags',
          url: '/icons/flags',
          icon: 'icon-star',
        },
        {
          name: 'Font Awesome',
          url: '/icons/font-awesome',
          icon: 'icon-star',
          badge: {
            variant: 'secondary',
            text: '4.7',
          },
        },
        {
          name: 'Simple Line Icons',
          url: '/icons/simple-line-icons',
          icon: 'icon-star',
        },
      ],
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'icon-bell',
      children: [
        {
          name: 'Alerts',
          url: '/notifications/alerts',
          icon: 'icon-bell',
        },
        {
          name: 'Badges',
          url: '/notifications/badges',
          icon: 'icon-bell',
        },
        {
          name: 'Modals',
          url: '/notifications/modals',
          icon: 'icon-bell',
        },
      ],
    },
    {
      name: 'Widgets',
      url: '/widgets',
      icon: 'icon-calculator',
      badge: {
        variant: 'info',
        text: 'NEW',
      },
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'Extras',
    },
    {
      name: 'Pages',
      url: '/pages',
      icon: 'icon-star',
      children: [
        {
          name: 'Login',
          url: '/login',
          icon: 'icon-star',
        },
        {
          name: 'Register',
          url: '/register',
          icon: 'icon-star',
        },
        {
          name: 'Error 404',
          url: '/404',
          icon: 'icon-star',
        },
        {
          name: 'Error 500',
          url: '/500',
          icon: 'icon-star',
        },
      ],
    },*/

  ],
};
