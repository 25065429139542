import React, { Component } from 'react';
import {  Route, Router } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from './_helpers';
import { alertActions } from './_actions';
import { PrivateRoute } from './_components';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

import './assets/css/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views/Pages';

// import { renderRoutes } from 'react-router-config';

class BaseApp extends Component {
  constructor(props) {
      super(props);
      const { dispatch } = this.props;
      history.listen((location, action) => {
          // clear alert on location change
          dispatch(alertActions.clear());
      });

  }
  render() {
    const { alert } = this.props;

    return (
            <div>
                    {alert && alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                    }
                    <Router history={history}>
                        <div>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/404" name="Page 404" component={Page404} />
                            <Route exact path="/500" name="Page 500" component={Page500} />
                            <PrivateRoute  path="/" component={DefaultLayout} />
                        </div>
                    </Router>
            </div>
    );
    // return (
    //   <Router>
    //     <Switch>
    //       <Route exact path="/login" name="Login Page" component={Login} />
    //       <Route exact path="/register" name="Register Page" component={Register} />
    //       <Route exact path="/404" name="Page 404" component={Page404} />
    //       <Route exact path="/500" name="Page 500" component={Page500} />
    //       <Route path="/" name="Home" component={DefaultLayout} />
    //     </Switch>
    //   </Router>
    // );
  }
}
function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}
const App = connect(mapStateToProps)(BaseApp);
export default App;
//export default App;
